import { saveAs } from 'file-saver';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useMemo, useState } from 'react';
import { useClient, useQuery } from 'urql';

import {
  Card,
  CardBody,
  Form,
  SelectField,
  SelectOption,
  SubmitButton,
} from '@/components';
import { useElasticIndexPrefix, useTranslate } from '@/contexts';
import { BrandEnum } from '@/globalTypes';
import {
  PlayerSelfExclusionReport,
  PlayerSelfExclusionReportVariables,
} from './__generated__/PlayerSelfExclusionReport';
import { PlayerSelfExclusionReportBox } from './__generated__/PlayerSelfExclusionReportBox';
import { SanityPlayerSelfExclusionReportBlockStaticQuery } from './__generated__/SanityPlayerSelfExclusionReportBlockStaticQuery';

const BLOCK_STATIC_QUERY = graphql`
  query SanityPlayerSelfExclusionReportBlockStaticQuery {
    sanityPlayerSelfExclusionReportBlock {
      title {
        ...LocaleString
      }
      selectBrand {
        ...LocaleString
      }
      brandLabel {
        ...LocaleString
      }
      exportSelfExcluded {
        ...LocaleString
      }
    }
  }
`;

const QUERY = gql`
  query PlayerSelfExclusionReportBox {
    viewer {
      id
      brands {
        code
        name
      }
    }
  }
`;

const REPORT_QUERY = gql`
  query PlayerSelfExclusionReport($brand: BrandEnum!, $indexPrefix: String) {
    viewer {
      id
      exportedSelfExclusionsReport(brand: $brand, indexPrefix: $indexPrefix) {
        url
      }
    }
  }
`;

const PlayerSelfExclusionReportBlock = () => {
  const [isGenerating, setIsGenerating] = useState(false);
  const { t } = useTranslate();
  const client = useClient();
  const { indexPrefix } = useElasticIndexPrefix();
  const [{ data }] = useQuery<PlayerSelfExclusionReportBox>({
    query: QUERY,
  });

  const block = useStaticQuery<SanityPlayerSelfExclusionReportBlockStaticQuery>(
    BLOCK_STATIC_QUERY,
  ).sanityPlayerSelfExclusionReportBlock;

  const brandOptions = useMemo(() => {
    const brandOptions: SelectOption[] =
      data?.viewer.brands.map((brand) => ({
        label: brand.name,
        value: brand.code,
      })) ?? [];

    const options: SelectOption[] = [
      {
        label: t(block?.selectBrand),
        value: '',
      },
      ...brandOptions,
    ];

    return options;
  }, [data, block?.selectBrand, t]);

  if (!block) {
    return null;
  }

  const defaultValues = {
    brand: data?.viewer.brands?.[0].code as BrandEnum | '',
  };

  const onSubmit = ({ brand }: typeof defaultValues) => {
    if (!brand) {
      return;
    }

    setIsGenerating(true);

    client
      .query<PlayerSelfExclusionReport, PlayerSelfExclusionReportVariables>(
        REPORT_QUERY,
        { brand, indexPrefix },
        {
          requestPolicy: 'network-only',
        },
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.exportedSelfExclusionsReport.url;

        if (url) {
          saveAs(url, 'player-self-exclusion-report.csv');
        }
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody>
        <div className="p-3">
          <Form
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="grid gap-3"
          >
            <SelectField
              name="brand"
              id="PlayerSelfExclusionReportBlock__brand"
              title={t(block.brandLabel)}
              options={brandOptions}
              required
            />

            <SubmitButton
              value={t(block.exportSelfExcluded)}
              disabled={isGenerating}
            />
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerSelfExclusionReportBlock;
